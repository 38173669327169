import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import SEO from 'sections/Layout/SEO';

import {
  header,
  headerInner,
  cartLink,
  lookingForWrap,
  lookingFor,
  lookingForLink,
  modal,
  openStyle,
  narrow,
} from 'styles/pages/digitec/home.module.scss';
import 'styles/pages/digitec/general.scss';

const DigitecHome = () => {
  const [lookingModal, setLookingModal] = useState(false);

  const setLookingModalOpen = useCallback(() => {
    setLookingModal(true);
  }, []);

  const setLookingModalClose = useCallback(() => {
    setLookingModal(false);
  }, []);

  const seo = {
    title: 'digitec.ch Survey | Promo | Screver Feedback Management Solution',
    metaDesc:
      'Look at the digitec.ch website with our Screver Feedback Survey. It allows you to attract, collect, analyze in real-time, and implement data-driven actions.',
    opengraphUrl: 'https://screver.com/cases/cx/digitec/',
  };

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <SEO seo={seo} themeColor="#ffffff" />

      <div className={classnames('scr-digitect-wrap')}>
        <header className={header}>
          <div className={headerInner}></div>
          <Link to={'/cases/cx/digitec/cart'} className={cartLink}>
            <svg fill="none" viewBox="0 0 16 16" width="16" height="16">
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M15 4H3.728l2.225 6.113L15 8.19V4Zm1-1v6L5.311 11.272 1.936 2H0V1h2.636l.728 2H16ZM3.5 12a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3ZM14 13.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                clipRule="evenodd"
              ></path>
            </svg>

            <span>1</span>
          </Link>
        </header>
        <main>
          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitac-hero.jpg"
            alt=""
          />

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-main-1.png"
            alt=""
            className={narrow}
          />

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitac-main-2.png"
            alt=""
            className={narrow}
          />

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-main-3-1.png"
            alt=""
            className={narrow}
          />

          <div className={classnames(lookingForWrap, narrow)}>
            <img
              src="https://wp.screver.com/wp-content/uploads/2023/08/digitec-main-4-1.png"
              alt=""
            />

            <div className={lookingFor}>
              <div className="">
                <p>Hey, you didn't find what you were looking for?</p>
                <button
                  onClick={setLookingModalOpen}
                  className={lookingForLink}
                >
                  😔 Unfortunately not
                </button>
                <span className={lookingForLink}>🥰 I'm fine, thanks</span>
              </div>
            </div>
          </div>

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitac-disscus.png"
            alt=""
          />

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitac-main-recent.png"
            alt=""
          />

          <img
            src="https://wp.screver.com/wp-content/uploads/2023/08/digitac-main-footer.png"
            alt=""
          />

          <div className={classnames(modal, lookingModal ? openStyle : '')}>
            <button onClick={setLookingModalClose}></button>
            <iframe
              src="https://go.screver.com/screver-playground/digitec-search"
              frameBorder="0"
              title="Survey"
            ></iframe>
          </div>
        </main>
      </div>
    </>
  );
};

export default DigitecHome;
